import Phaser from "phaser"

var KeySpace
var KeyEnter

export default class Notice extends Phaser.Scene {
    // Variablen belegen
    private text!: string
    private caller!: string
    private answer!: boolean

    constructor() {
        super('notice')
    }

    // initialisieren der Notie Daten
    create(data) {
        this.scene.stop('hints')
        this.scene.stop('equipment')
        this.text = data.text
        this.caller = data.caller
        this.answer = data.answer


        // Rechteck und Text für Notice erstellen
        const notice = this.add.rectangle(512, 288, 600, 300, 0xFFFAFA).setStrokeStyle(2, 0x000000)
        this.add.text(notice.getLeftCenter().x + 50, 268, this.text, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'center',
            wordWrap: {
                width: 550
            }
        })
        // Text hinzufügen
        const text = this.add.text(512, 342, 'OK', {
            fontSize: '28px',
            color: '#0f0'
        }).setInteractive()

        // Event wenn der 'OK' Text bestätigt wird
        text.on('pointerdown', () => {
            this.scene.stop()
            this.scene.resume(this.caller, {caller: 'notice'})
        })

        // Tastaturkeys zum Schließen des Hinweis belegen
        if(this.input?.keyboard){
        KeySpace = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE)
        KeyEnter = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER)
        }

    }

    // Tastatureingaben abfangen, um Hinweis mit Space oder Enter wegdrücken zu können
    update() {
        if (Phaser.Input.Keyboard.JustDown(KeySpace) || Phaser.Input.Keyboard.JustDown(KeyEnter)) {
            this.scene.stop()
            this.scene.start('equipment')
            this.scene.resume(this.caller, {caller: 'notice'})
        }
    }
}
