import Phaser from 'phaser'

var KeyEsc
var KeyEnt
var KeyNew
var KeyM
let cursor
var zone
var zone_2
var container_1
var container_2
var container_3
var container_4
var container_5
var container_6
var answerArray
var length
var container_top
var answerArr

export default class DragDropZone extends Phaser.Scene {
    // Variablen belegen
    private question!: string
    private answer1!: string
    private answer2!: string
    private answer3!: string
    private answer4!: string
    private answer5!: string
    private answer6!: string
    private rightAnswer!: []
    private caller!: string
    private bool!: boolean
    private tension!: Phaser.Sound.BaseSound
    private backgroundmusic!: Phaser.Sound.BaseSound
    private explanation!: string

    constructor() {
        super('dragDropZone')
    }

    init(data) {
        // Parameter in der Scene initialisieren
        this.question = data.question
        this.answer1 = data.answer1
        this.answer2 = data.answer2
        this.answer3 = data.answer3
        this.answer4 = data.answer4
        this.answer5 = data.answer5
        this.answer6 = data.answer6
        this.rightAnswer = data.rightAnswer
        this.caller = data.caller
        this.explanation = data.explanation
    }

    create() {
        // Szene generieren
        this.scene.stop('hints')
        this.scene.stop('equipment')

        //Musik anschalten, wenn in vorherigem Level Musik an war
        var flag = localStorage.getItem('flag')
        if (flag == "true") {
            this.backgroundmusic = this.sound.get('backgroundmusic')
            this.sound.stopAll()
            this.tension = this.sound.add('question', {volume: 0.1, loop: true})
            this.tension.play()
        }

        // Hintergrundbild
        this.add.image(512, 250, 'background')

        // Rechteck für Die Frage
        const questionRect = this.add.graphics()
        questionRect.fillStyle(0xFFFAFA)
        questionRect.fillRoundedRect(50, 20, 924, 100, 25)


        // Rechtecke für die Antworten
        const answer1RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('1')
        const answer2RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('2')
        const answer3RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('3')
        const answer4RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('4')
        const answer5RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('5')
        const answer6RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('6')


        // Texte der Antworten und Fragen
        this.add.text(110, 60, this.question, {
            fontSize: '18px',
            color: '0xffff00',
            align: 'center',
            wordWrap: {
                width: 824
            }
        })

        // Antwort 1
        const answer1Text = this.add.text(-150, -20, this.answer1, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })

        // Container für Antwort 1
        container_1 = this.add.container(525, 175, [answer1RectR, answer1Text])
        container_1.setSize(400, 70)
        container_1.setInteractive()
        container_1.setName('1')
        this.input.setDraggable(container_1)


        // Antwort 2
        const answer2Text = this.add.text(-150, -20, this.answer2, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })

        // Container für Antwort 2
        container_2 = this.add.container(525, 175, [answer2RectR, answer2Text])
        container_2.setSize(400, 70)
        container_2.setInteractive()
        container_2.setName('2')
        this.input.setDraggable(container_2)


        // Antwort 3
        const answer3Text = this.add.text(-150, -20, this.answer3, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })

        // Container für Antwort 3
        container_3 = this.add.container(525, 175, [answer3RectR, answer3Text])
        container_3.setSize(400, 70)
        container_3.setInteractive()
        container_3.setName('3')
        this.input.setDraggable(container_3)

        // Antwort 4
        const answer4Text = this.add.text(-150, -20, this.answer4, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })
// Container für Antwort 4
        container_4 = this.add.container(525, 175, [answer4RectR, answer4Text])
        container_4.setSize(400, 70)
        container_4.setInteractive()
        container_4.setName('4')
        this.input.setDraggable(container_4)

        // Antwort 5
        const answer5Text = this.add.text(-150, -20, this.answer5, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })
// Container für Antwort 5
        container_5 = this.add.container(525, 175, [answer5RectR, answer5Text])
        container_5.setSize(400, 70)
        container_5.setInteractive()
        container_5.setName('5')
        this.input.setDraggable(container_5)


        // Antwort 6
        const answer6Text = this.add.text(-150, -20, this.answer6, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })
// Container für Antwort 6
        container_6 = this.add.container(525, 175, [answer6RectR, answer6Text])
        container_6.setSize(400, 70)
        container_6.setInteractive()
        container_6.setName('6')
        this.input.setDraggable(container_6)

        //Dropzones
        this.add.image(265, 225, 'right').setScale(0.1)
        this.add.image(775, 225, 'wrong').setScale(0.1)
        zone = this.add.zone(275, 415, 400, 300).setRectangleDropZone(400, 300)
        zone.setName('right')
        zone.setData({cards: 0})
        zone_2 = this.add.zone(750, 415, 400, 300).setRectangleDropZone(400, 300)
        zone_2.setName('wrong')
        zone_2.setData({cards: 0})

        //  Rechtecke für Dropzones
        var graphics = this.add.graphics()
        graphics.lineStyle(2, 0x000ff00)
        graphics.strokeRect(zone.x - zone.input.hitArea.width / 2, zone.y - zone.input.hitArea.height / 2, zone.input.hitArea.width, zone.input.hitArea.height)

        var graphics_2 = this.add.graphics()
        graphics_2.lineStyle(2, 0xff00110)
        graphics_2.strokeRect(zone_2.x - zone_2.input.hitArea.width / 2, zone_2.y - zone_2.input.hitArea.height / 2, zone_2.input.hitArea.width, zone_2.input.hitArea.height)

        container_top = 5;

// Drag and Drop Definition
        this.input.on('dragstart', (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Container) => {
            this.children.bringToTop(gameObject)

        }, this)

        this.input.on('drag', function (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Container, dragX, dragY) {

            gameObject.x = dragX
            gameObject.y = dragY

        })

        this.input.on('dragenter', function (pointer: Phaser.Input.Pointer, gameObject, dropZone: Phaser.GameObjects.Rectangle) {
            if (dropZone.name == "right") {
                graphics.clear()
                graphics.lineStyle(2, 0x000ff00)
                graphics.strokeRect(zone.x - zone.input.hitArea.width / 2, zone.y - zone.input.hitArea.height / 2, zone.input.hitArea.width, zone.input.hitArea.height)
            } else {
                graphics_2.clear()
                graphics_2.lineStyle(2, 0xff00110)
                graphics_2.strokeRect(zone_2.x - zone_2.input.hitArea.width / 2, zone_2.y - zone_2.input.hitArea.height / 2, zone_2.input.hitArea.width, zone_2.input.hitArea.height)
            }
        })

        this.input.on('dragleave', function (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Container, target: Phaser.GameObjects.Rectangle) {

            if (target.name == "right") {
                graphics.clear()
                graphics.lineStyle(2, 0x000ff00)
                graphics.strokeRect(zone.x - zone.input.hitArea.width / 2, zone.y - zone.input.hitArea.height / 2, zone.input.hitArea.width, zone.input.hitArea.height)
            } else {
                graphics_2.clear()
                graphics_2.lineStyle(2, 0xff00110)
                graphics_2.strokeRect(zone_2.x - zone_2.input.hitArea.width / 2, zone_2.y - zone_2.input.hitArea.height / 2, zone_2.input.hitArea.width, zone_2.input.hitArea.height)
            }
        })

        this.input.on('drop', function (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Container, dropZone: Phaser.GameObjects.Rectangle) {
            gameObject.x = dropZone.x
            gameObject.y = (dropZone.y - 115) + (dropZone.data.values.cards * 70)
            dropZone.data.values.cards++
            gameObject.disableInteractive()
            container_top--;
        })

        this.input.on('dragend', function (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Container, dropped) {
            if (!dropped) {
                if(gameObject?.input){
                gameObject.x = gameObject.input.dragStartX
                gameObject.y = gameObject.input.dragStartY
                }
            }
        })

        var notice_count = localStorage.getItem('notice_count_1');
        if (notice_count == 'right') {
            this.scene.launch('notice', {
                text: 'Du kannst die Kärtchen auch mit den Pfeiltasten deiner Tastatur nach links oder rechts bewegen.',
                caller: 'DragDropZone'
            })
        }
        localStorage.setItem('notice_count_1', "false");

        answerArr = [container_1, container_2, container_3, container_4, container_5, container_6]

// Button Überprüfen
        length = this.rightAnswer.length
        answerArray = this.rightAnswer
        const clickButton = this.add.text(820, 140, `Überprüfen`, {
            color: 'black',
            fontSize: '25px',
            stroke: 'black',
            strokeThickness: 2,
            backgroundColor: 'lightgreen'
        })
            .setInteractive()


        // Überprüfen der Antworten
        clickButton.on('pointerdown', () => {
            this.sound.remove(this.tension)
            if (flag == "true") {
                this.backgroundmusic.play()
            }

            var right = 0
            var wrong = 0
            var count = 0
            var arr = [container_1, container_2, container_3, container_4, container_5, container_6]
            for (var i = 0; i < arr.length; i++) {
                count = 0
                for (var j = 0; j < length; j++) {
                    if (answerArray[j] == arr[i].name) {
                        if (arr[i].x >= zone.x && arr[i].x < zone_2.x && arr[i].y + 115 >= zone.y) {
                            right = right + 1
                            break
                        } else {
                            wrong = wrong + 1
                            break
                        }
                    } else {
                        count = count + 1
                    }
                }
                if (count == answerArray.length) {
                    if (arr[i].x >= zone_2.x) {
                        right = right + 1
                    } else {
                        wrong = wrong + 1
                    }
                }
            }
            if (wrong == 0) {
                this.bool = true
                this.scene.launch('warning', {
                    text: this.explanation,
                    header: 'Das war richtig. \n Du kannst in den nächsten Raum.',
                    caller: 'dragDropZone',
                    answer: true
                })
                this.scene.pause()

            } else {
                this.bool = false
                this.scene.launch('warning', {
                    text: '',
                    header: 'Das war leider falsch. \n Du musst das Level nochmal spielen.',
                    caller: 'dragDropZone',
                    answer: false
                })
                this.scene.pause()
            }
        })

        // Button Neustart
        const clickButton_2 = this.add.text(820, 190, `Neustart`, {
            color: 'black',
            fontSize: '25px',
            stroke: 'black',
            strokeThickness: 2,
            backgroundColor: 'lightgrey'
        })
            .setInteractive()

        // Neustart der Scene
        clickButton_2.on('pointerdown', () => {
            this.scene.restart();
        })

        // Frageszene schließen und Szene davor weiterlaufen lassen
        this.events.on('resume', () => {
            this.scene.stop()
            this.scene.start('equipment')
            this.scene.start('mapButton')
            this.scene.start('musikButton')
            this.scene.resume(this.caller, {bool: this.bool, caller: 'dragDropZone'})
        })

        // Tastaturkeys zum Beantworten der Frage belegen
        if(this.input?.keyboard){
            KeyEsc = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ESC)
            KeyEnt = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER)
            KeyNew = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.N)
            cursor = this.input.keyboard.createCursorKeys()
        }

        //Tasten belegen für Musiksteuerung
        //KeyM = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M)

    }

    resolve() {
        this.sound.remove(this.tension)
        var flag = localStorage.getItem('flag');
        if (flag == "true") {
            this.backgroundmusic.play()
        }

        var right = 0
        var wrong = 0
        var count = 0
        var arr = [container_1, container_2, container_3, container_4, container_5, container_6]
        for (var i = 0; i < arr.length; i++) {
            count = 0
            for (var j = 0; j < length; j++) {
                if (answerArray[j] == arr[i].name) {
                    if (arr[i].x >= zone.x && arr[i].x < zone_2.x && arr[i].y + 115 >= zone.y) {
                        right = right + 1
                        break
                    } else {
                        wrong = wrong + 1
                        break
                    }
                } else {
                    count = count + 1
                }
            }
            if (count == answerArray.length) {
                if (arr[i].x >= zone_2.x) {
                    right = right + 1
                } else {
                    wrong = wrong + 1
                }
            }
        }
        if (wrong == 0) {
            this.bool = true
            this.scene.launch('warning', {
                text: 'Das war richtig. Du kannst in den nächsten Raum vordringen.',
                caller: 'dragDropZone',
                answer: true,
            })
            this.scene.pause()

        } else {
            this.bool = false
            this.scene.launch('warning', {
                text: '',
                caller: 'dragDropZone',
                answer: false,
            })
            this.scene.pause()
        }
    }

    // Tastatureingaben abfangen, um Frage zu beantworten oder zu schließen
    update() {
        if (container_top >= 0) {
            if (Phaser.Input.Keyboard.JustDown(cursor.left)) {
                answerArr[container_top].x = zone.x
                answerArr[container_top].y = (zone.y - 115) + (zone.data.values.cards * 70)
                zone.data.values.cards++
                container_top--;
            } else if (Phaser.Input.Keyboard.JustDown(cursor.right)) {
                answerArr[container_top].x = zone_2.x
                answerArr[container_top].y = (zone_2.y - 115) + (zone_2.data.values.cards * 70)
                zone_2.data.values.cards++
                container_top--;
            }
        } else if (Phaser.Input.Keyboard.JustDown(KeyEsc)) {
            // Escape gedrückt: Frage schließen ohne zu beantworten
            this.scene.stop()
            this.scene.start('equipment')
            this.scene.start('mapButton')
            this.scene.start('musikButton')
            this.scene.resume(this.caller, {caller: 'questionScene'})
        } else if (Phaser.Input.Keyboard.JustDown(KeyEnt)) {
            // Enter gedrückt: Frage überprüfen
            this.resolve();
        } else if (Phaser.Input.Keyboard.JustDown(KeyNew)) {
            // N gedrückt: Fragesezene neu starten
            this.scene.restart();
        }
    }

}