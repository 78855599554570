import Phaser, { GameObjects } from 'phaser'
import '../../characters/Thief'
import Thief from "../../characters/Thief"
import Officer3 from "../../enemies/Officer3"
import {createThiefAnimations} from "../../animations/ThiefAnimation"
import {sceneEvents} from "../../events/EventsCenter"
import {Hints, hints} from "../../assets/classes/Hints"
import {Karten, karten } from "../../assets/classes/Karten"
import {createOfficerAnimations} from "../../animations/EnemyAnimation"
import QuestionLoader from '../../assets/classes/QuestionLoader'

var keyM

export default class Level4 extends Phaser.Scene {
    // Variablen belegen
    private cursors!: Phaser.Types.Input.Keyboard.CursorKeys
    private thief!: Thief
    private officers!: Phaser.Physics.Arcade.Group
    private hintNum!: number
    private karte: Karten = new Karten(4)
    private karten: Karte[] = this.karte.getKarten()
    private kartenNum!: number

    private questionText!: string;
    private options!: string[];
    private explanation!: string;
    private randomQuestion: any;
    private rightAnswer: any;
    private questionId!: number;

    constructor() {
        // Variablen vorbelegen
        super('level4')
        //this.question.done = true
        this.hintNum = 4
        this.kartenNum = 1
    }

    preload() {
        // Tilesets laden
        if(this.input?.keyboard){
        this.cursors = this.input.keyboard.createCursorKeys()
        }
    }

    create() {
        this.cameras.main.fadeIn(500)
        if (this.hintNum == 4) {
            this.scene.launch('hints', {
                content: hints[4].content
            })
        }

        // Animationen erzeugen
        createThiefAnimations(this.anims)
        createOfficerAnimations(this.anims)

        const map = this.make.tilemap({key: 'map4'})

        // Tilesets hinzufügen
        const tileset7 = map.addTilesetImage('build_atlas', 'tiles1')
        const tileset8 = map.addTilesetImage('Building', 'tiles2')
        const tileset1 = map.addTilesetImage('Cars_final', 'tiles3')
        const tileset6 = map.addTilesetImage('city_outside', 'tiles4')
        const tileset11 = map.addTilesetImage('inside', 'tiles5')
        const tileset12 = map.addTilesetImage('Interior-Furniture', 'tiles6')
        const tileset10 = map.addTilesetImage('Interior-Walls-Beige', 'tiles7')
        const tileset14 = map.addTilesetImage('Laserfence', 'tiles8')
        const tileset9 = map.addTilesetImage('Objects', 'tiles9')
        const tileset2 = map.addTilesetImage('Street', 'tiles10')
        const tileset5 = map.addTilesetImage('terrain_atlas', 'tiles11')
        const tileset4 = map.addTilesetImage('tileset_town_multi_v002', 'tiles12')
        const tileset3 = map.addTilesetImage('treetop', 'tiles13')
        const tileset13 = map.addTilesetImage('woodland_indoor', 'tiles14')

        // Tilesets zu Layer hinzufügen
        //const allLayers: Array<Phaser.Tilemaps.Tileset | null>  = [tileset1, tileset2, tileset3, tileset4, tileset5, tileset6, tileset7, tileset8, tileset9, tileset10, tileset11, tileset12, tileset13, tileset14]
        const allLayers: Array<Phaser.Tilemaps.Tileset>  = [];//[tileset1, tileset2, tileset3, tileset4, tileset5, tileset6, tileset7, tileset8, tileset9, tileset10, tileset11, tileset12, tileset13, tileset14]
        if(tileset1)allLayers.push(tileset1);
        if(tileset2)allLayers.push(tileset2);
        if(tileset3)allLayers.push(tileset3);
        if(tileset4)allLayers.push(tileset4);
        if(tileset5)allLayers.push(tileset5);
        if(tileset6)allLayers.push(tileset6);
        if(tileset7)allLayers.push(tileset7);
        if(tileset8)allLayers.push(tileset8);
        if(tileset9)allLayers.push(tileset9);
        if(tileset10)allLayers.push(tileset10);
        if(tileset11)allLayers.push(tileset11);
        if(tileset12)allLayers.push(tileset12);
        if(tileset13)allLayers.push(tileset13);
        if(tileset14)allLayers.push(tileset14);

        // Gebäude, Mauern und Objekte zu Layern hinzufügen
        map.createLayer(0, allLayers)
        const gebaeude = map.createLayer(1, allLayers)
        const gebaeudeMauer = map.createLayer(2, allLayers)
        const objekte = map.createLayer(3, allLayers)


        // Thief erzeugen
        var doorLevel3 = localStorage.getItem('doorPosition')
        if (doorLevel3 == "true") {
            this.thief = this.add.thief(2000, -750, 'thief')
            if(this.thief?.body){
                this.thief.body.offset.y = 16
            }
            this.thief.questionsSolved = 0
        } else if (doorLevel3 == "false"){
            this.thief = this.add.thief(1650, -750, 'thief')
            if(this.thief?.body){
                this.thief.body.offset.y = 16
            }
            this.thief.questionsSolved = 0
        }

        // Kollission
        objekte?.setCollisionByExclusion([-1])
        gebaeudeMauer?.setCollisionByExclusion([-1])
        gebaeude?.setCollisionByExclusion([-1])

        //QuestionLoader
        const questionLoader = new QuestionLoader();

        questionLoader.loadQuestion(4).then(questionData => {
          this.questionText = questionData.questionText;
          this.options = questionData.options;
          this.randomQuestion = questionData;
          this.rightAnswer = questionData.rightAnswer;
          this.explanation = questionData.explanation;
          this.questionId = questionData.questionId;
    
          const hints = questionData.hints;
        });

        this.cameras.main.startFollow(this.thief, true)

        //Tasten belegen für Musiksteuerung
        if(this.input?.keyboard){
            keyM = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M)
        }

        // Officers erzeugen
        this.officers = this.physics.add.group({
            classType: Officer3,
            createCallback: (go) => {
                const offGo = go as Officer3;
                if(offGo?.body){
                    offGo.body.onCollide = true;
                }
                offGo.setThief(this.thief); // Setze die Eigenschaft "thief" für jeden Officer
            }
        });

        let officer = this.officers.get(1800, -1050, 'officer');
        officer.setSize(30, 32);
        officer.body.offset.y = 16;

        // Rectangle über Türe legen, damit Thief damit colliden kann
        var door = this.add.rectangle(1830, -1112, 150, 50);
        this.physics.add.existing(door, true);

        //für Frage colliden
        var questionField = this.add.rectangle(1970, -1112, 18, 55);
        this.physics.add.existing(questionField, true);


        // Collider hinzufügen
        if(objekte) this.physics.add.collider(this.thief, objekte)
        if(gebaeudeMauer) this.physics.add.collider(this.thief, gebaeudeMauer)
        if(objekte) this.physics.add.collider(this.officers, objekte)
        if(gebaeudeMauer) this.physics.add.collider(this.officers, gebaeudeMauer)
        if(gebaeude)this.physics.add.collider(this.officers, gebaeude)
        
        this.physics.add.collider(this.thief, this.officers, this.handleThiefOfficerCollision, undefined, this)
        this.physics.add.collider(this.thief, door, this.handleThiefDoorCollision, undefined, this)
        this.physics.add.collider(this.thief, questionField, this.handleQuestionThiefCollision, undefined, this)

        //Steuerung
        this.scene.launch('joystickTouch', {
            thief: this.thief
        });

        //PausenButton erstellen
        this.scene.launch('pausenButton', {
            pausedSceneKey: this.scene.key
        })

        //MapButton erstellen
        this.scene.launch('mapButton')

        //MusikButton erstellen
        this.scene.launch('musikButton')

        // Ruft Stoppuhr auf
        this.scene.launch("stopwatchscene")

        // walkietalkie
        this.scene.launch('equipment')

        this.events.on('resume', (sys, data) => {
            this.scene.launch('joystickTouch', {
                thief: this.thief
            });
            
            if (data.caller === 'pausenButton') {
                this.hintNum = 4
                this.kartenNum = 0
            } else {
                if (data.bool && data.caller === 'questionScene') {
                    try {
                        this.physics.world.disable(questionField)
                    }
                    catch(err) {
                        console.log("Could not disable question field!", err)
                    }
                    sceneEvents.emit('solved-question')
                } else {
                    this.hintNum = 4
                    this.kartenNum = 0
                    this.scene.stop()
                    this.scene.start()
                }
            }
        })

       // Hinweise anzeigen
       sceneEvents.on('getHint', () => {
        if (this.scene.isActive('karten')) {
            this.scene.setVisible(false, 'karten')
        }

        this.scene.launch('hints', {
            content: hints[this.hintNum].content
        })
    })

    //Karten anzeigen
    sceneEvents.on('getKarten', () => {
        if (this.scene.isActive('hints')) {
            this.scene.setVisible(false, 'hints')
            this.scene.launch('equipment')
        }

        this.scene.launch('karten', {
            content: this.karten[0].content
        })
    })
    }

    handleThiefOfficerCollision(obj1: Phaser.Tilemaps.Tile | Phaser.GameObjects.GameObject, obj2: Phaser.Tilemaps.Tile | Phaser.GameObjects.GameObject) {
        // Thief - Officer Kollission handlen
        this.events.removeListener('resume')
        this.scene.stop()
        this.scene.stop('mapButton')
        this.scene.stop('karten')
        this.scene.stop('musikButton')
        this.scene.stop('joystickTouch')
        this.scene.stop('pausenButton')
        this.scene.start('gameOver', {caller: 'level4'})
    }

    handleThiefDoorCollision(obj1: Phaser.Tilemaps.Tile |Phaser.GameObjects.GameObject, obj2: Phaser.Tilemaps.Tile |Phaser.GameObjects.GameObject) {
        // Thief - Tür Kollission handlen
        this.thief.setDoor()
        sceneEvents.once('openDoor', () => {
            this.scene.pause()
            this.scene.stop('mapButton')
            this.scene.stop('karten')
            this.scene.stop('musikButton')
            // Falls nicht alle Fragen beantwortet worden, muss Hinweis ausgegeben werden
            if (this.thief.questionsSolved < 1) {
                this.scene.launch('warning', {
                    text: 'Du musst erst alle Fragen dieses Levels beantworten.',
                    caller: 'level4'
                })
            } else {
                this.scene.start('level5')

            }
        })
    }

    handleQuestionThiefCollision(obj1: Phaser.Tilemaps.Tile |Phaser.GameObjects.GameObject, obj2:Phaser.Tilemaps.Tile | Phaser.GameObjects.GameObject) {
        // Thief - Question Kollission handlen
        this.thief.setQuestion()
        sceneEvents.once('openQuestion', () => {
            this.scene.pause()
            this.scene.stop('mapButton')
            this.scene.stop('karten')
            this.scene.stop('joystickTouch')
            // Wiederverwendbare Szene mit Parametern aufrufen
            this.scene.launch('questionScene', {
                question: this.questionText,
                answer1: this.options[0],
                answer2: this.options[1],
                answer3: this.options[2],
                rightAnswer: this.rightAnswer,
                explanation: this.explanation,
                caller: 'level4'
            })
        })
    }

    

    update(time: number, delta: number) {
        const childs = this.officers.getChildren();
        if (childs) {
            try{
                childs.forEach((officer) => {
                    officer.update(time, delta); // Rufe das Update für jeden Officer auf
                });
            }
            catch(e){
                console.error(e);
            }    
        }
    }     
}

