import Phaser, { GameObjects } from 'phaser'
import VirtualJoyStick from 'phaser3-rex-plugins/plugins/virtualjoystick';
import {sceneEvents} from "../events/EventsCenter";

declare global {
    // Thief global deklarieren
    namespace Phaser.GameObjects {
        interface GameObjectFactory {
            thief(x: number, y: number, texture: string, frame?: string | number): Thief
        }
    }
}

export default class Thief extends Phaser.Physics.Arcade.Sprite {
    // Variablen belegen
    private _questionsSolved
    private activeQuestion: boolean = false
    private activeDoor: boolean = false

    get questionsSolved() {
        // Gibt gelöste Fragen zurück
        return this._questionsSolved
    }

    set questionsSolved(number) {
        // Setzt die gelösten Fragen
        this._questionsSolved = number
    }

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {

        super(scene, x, y, texture, frame)
        // Eigenschaften des Thief definieren
        this.anims.play('thief-idle-down')
        this.scale = 1.5
        this.width = 16
        this.height = 16
        sceneEvents.on('solved-question', () => {
            this._questionsSolved++
        })
    }

    setQuestion() {
        this.activeQuestion = true
    }

    setDoor() {
        this.activeDoor = true
    }

    update(cursors: Phaser.Types.Input.Keyboard.CursorKeys, joyStick: VirtualJoyStick, buttonRechts: Phaser.GameObjects.GameObject, buttonLinks: Phaser.GameObjects.GameObject) {
        // Interaktionen des Thiefs belegen
        if (!cursors || !joyStick) {
            return
        }

        if (Phaser.Input.Keyboard.JustDown(cursors.space!)) {
            if (this.activeQuestion) {
                sceneEvents.emit('openQuestion')
            } else if (this.activeDoor) {
                sceneEvents.emit('openDoor')
            }
        }

        //Button Aktion Rechts: Frage öffnen bei Klick 
        buttonRechts.on('pointerdown', () => { 
            if (this.activeQuestion) {
                sceneEvents.emit('openQuestion')
            } else if (this.activeDoor) {
                sceneEvents.emit('openDoor')
            }
        });

        buttonRechts.on('pointerup', function(){
        });

        // Schneller laufen über SHIFT oder Linken Button
        var speed = 0

        var flag_runner = localStorage.getItem('flag_run')

        if (cursors.shift?.isDown || flag_runner == "true") {
            speed = 225
        } else {
            speed = 150
        }

        // Keytasten belegen
        const keyLeft = cursors.left?.isDown
        const keyRight = cursors.right?.isDown
        const keyUp = cursors.up?.isDown
        const keyDown = cursors.down?.isDown

        // Joystick Bewegung belegen
        var leftKeyDown = joyStick.left;
        var rightKeyDown = joyStick.right;
        var upKeyDown = joyStick.up;
        var downKeyDown = joyStick.down;
        var noKeyDown = joyStick.noKey;

        if (keyLeft || joyStick.left) {
            // nach links laufen
            this.anims.play('thief-run-side', true)
            this.setVelocity(-speed, 0)
            
            if( this.body ) {
                this.scaleX = -1.5
                this.body.offset.x = 18
            }
        } else if (keyRight || joyStick.right) {
            // nach rechts laufen
            this.anims.play('thief-run-side', true)
            this.setVelocity(speed, 0)
           
            if( this.body ) {
                this.scaleX = 1.5
                this.body.offset.x = 0
            }
        } else if (keyUp || joyStick.up) {
            // nach oben laufen
            this.anims.play('thief-run-up', true)
            this.setVelocity(0, -speed)
        } else if (keyDown || joyStick.down) {
            // nach unten laufen
            this.anims.play('thief-run-down', true)
            this.setVelocity(0, speed)
        } else {
            if( this.anims && this.anims.currentAnim ) {
                const parts = this.anims.currentAnim.key.split('-');
                parts[1] = 'idle';
                this.anims.play(parts.join('-'));
                this.setVelocity(0, 0);
            }
            else {
                console.log("Current anim not loaded!")
;            }
        }

        if (keyLeft || keyRight || keyUp || keyDown || joyStick.left || joyStick.right || joyStick.up || joyStick.down) {
            this.activeQuestion = false
            this.activeDoor = false
        }
 


    }


}
// Thief als GameObject registrieren
Phaser.GameObjects.GameObjectFactory.register('thief', function (this: Phaser.GameObjects.GameObjectFactory, x: number, y: number, texture: string, frame?: string | number) {
    var sprite = new Thief(this.scene, x, y, texture, frame)

    this.displayList.add(sprite)
    this.updateList.add(sprite)

    this.scene.physics.world.enableBody(sprite, Phaser.Physics.Arcade.DYNAMIC_BODY)

    if( sprite.body) {
        sprite.body.setSize(sprite.width * 0.8, sprite.height * 0.9)
    }
    else {
        console.log( "Sprite body not found!");
    }
    return sprite
})
