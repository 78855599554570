import Phaser from 'phaser'

var KeyOne
var KeyNumOne
var KeyTwo
var KeyNumTwo
var KeyThree
var KeyNumThree
var KeyFour
var KeyNumFour
var KeyFive
var KeyNumFive
var KeySix
var KeyNumSix
var KeyEsc
var KeyEnt
var KeyNew
var KeyM
let cursor
var container_1
var container_2
var container_3
var container_4
var container_5
var container_6
var handle1
var handle2
var handle3
var handle4
var handle5
var handle6
var graphics
var graphics2
var graphics3
var answerArray
var length
var counter
var notice


export default class QuestionLineScene extends Phaser.Scene {
    // Variablen belegen
    private question!: string
    private answer1!: string
    private answer2!: string
    private answer3!: string
    private answer4!: string
    private answer5!: string
    private answer6!: string
    private rightAnswer!: []
    private caller!: string
    private bool!: boolean;
    private tension!: Phaser.Sound.BaseSound
    private backgroundmusic!: Phaser.Sound.BaseSound
    private explanation!: string

    constructor() {
        super('questionLineScene');
    }

    init(data) {
        // Parameter in der Scene initialisieren
        this.question = data.question
        this.answer1 = data.answer1
        this.answer2 = data.answer2
        this.answer3 = data.answer3
        this.answer4 = data.answer4
        this.answer5 = data.answer5
        this.answer6 = data.answer6
        this.rightAnswer = data.rightAnswer
        this.caller = data.caller
        this.explanation = data.explanation

    }

    create() {
        // Szene generieren
        this.scene.stop('hints')
        this.scene.stop('equipment')

        //Musik anschalten, wenn in vorherigem Level Musik an war
        var flag = localStorage.getItem('flag')
        if (flag == "true") {
            this.backgroundmusic = this.sound.get('backgroundmusic')
            this.sound.stopAll()
            this.tension = this.sound.add('question', {volume: 0.1, loop: true})
            this.tension.play()
        }


        // Hintergrundbild
        this.add.image(512, 250, 'background')

        counter = 0

        // Rechteck für Die Frage
        const questionRect = this.add.graphics()
        questionRect.fillStyle(0xFFFAFA)
        questionRect.fillRoundedRect(50, 50, 924, 100, 15)


        // Rechtecke für die Antworten
        const answer1RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('1')
        const answer2RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('2')
        const answer3RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('3')
        const answer4RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('4')
        const answer5RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('5')
        const answer6RectR = this.add.rectangle(0, 0, 400, 70, 0xFFFAFA).setName('6')


        // Texte der Antworten und Fragen
        this.add.text(110, 100, this.question, {
            fontSize: '18px',
            color: '0xffff00',
            align: 'center',
            wordWrap: {
                width: 824
            }
        })

        // Antwort 1
        const answer1Text = this.add.text(-150, -20, this.answer1, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })

        // Container für Antwort 1
        container_1 = this.add.container(300, 300, [answer1RectR, answer1Text])
        container_1.setSize(400, 70)
        container_1.setName('1');

        // Antwort 2
        const answer2Text = this.add.text(-150, -20, this.answer2, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })

        // Container für Antwort 2
        container_2 = this.add.container(300, 400, [answer2RectR, answer2Text])
        container_2.setSize(400, 70)
        container_2.setName('2')

        // Ziehbare Punkte für Linien
        handle1 = this.add.sprite(500, 300, 'ball', 0);

        handle2 = this.add.sprite(500, 300, 'ball', 0);
        handle2.setInteractive();
        this.input.setDraggable(handle2)
        handle2.setData({move: 0})

        graphics = this.add.graphics();
        graphics.lineStyle(2, 0xff00110, 1);

        // Antwort 3
        const answer3Text = this.add.text(-150, -20, this.answer3, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })

        // Container für Antwort 3
        container_3 = this.add.container(300, 500, [answer3RectR, answer3Text])
        container_3.setSize(400, 70)
        container_3.setName('3');

        // Antwort 4
        const answer4Text = this.add.text(-150, -20, this.answer4, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })

// Container für Antwort 4
        container_4 = this.add.container(750, 300, [answer4RectR, answer4Text])
        container_4.setSize(400, 70)
        container_4.setName('4');
        var zone_4 = this.add.zone(750, 300, 400, 70).setRectangleDropZone(400, 70);

        // Antwort 5
        const answer5Text = this.add.text(-150, -20, this.answer5, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })

        // Ziehbare Punkte für Linien
        handle3 = this.add.sprite(500, 400, 'ball', 0);

        handle4 = this.add.sprite(500, 400, 'ball', 0);
        handle4.setInteractive();
        this.input.setDraggable(handle4)
        handle4.setData({move: 0})

        graphics2 = this.add.graphics();
        graphics2.lineStyle(2, 0xff00110, 1);

// Container für Antwort 5
        container_5 = this.add.container(750, 400, [answer5RectR, answer5Text])
        container_5.setSize(400, 70)
        container_5.setName('5');
        var zone_5 = this.add.zone(750, 400, 400, 70).setRectangleDropZone(400, 70);

        // Antwort 6
        const answer6Text = this.add.text(-150, -20, this.answer6, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 350
            }
        })
// Container für Antwort 6
        container_6 = this.add.container(750, 500, [answer6RectR, answer6Text])
        container_6.setSize(400, 70)
        container_6.setName('6');
        var zone_6 = this.add.zone(750, 500, 400, 70).setRectangleDropZone(400, 70);

        // Ziehbare Punkte für Linien
        handle5 = this.add.sprite(500, 500, 'ball', 0);

        handle6 = this.add.sprite(500, 500, 'ball', 0);
        handle6.setInteractive();
        this.input.setDraggable(handle6)
        handle6.setData({move: 0})

        graphics3 = this.add.graphics();
        graphics3.lineStyle(2, 0xff00110, 1);

        // Drag and Drop Definition
        this.input.on('dragstart', (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Container) => {
            this.children.bringToTop(gameObject);

        }, this);

        this.input.on('drag', function (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Container, dragX, dragY) {
            gameObject.x = dragX;
            gameObject.y = dragY;
        });

        this.input.on('drop', function (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Container, dropZone: Phaser.GameObjects.Rectangle) {
                    gameObject.x = dropZone.x - 200
                    gameObject.y = dropZone.y
                    gameObject.disableInteractive();
                    gameObject.data.values.move++
                    graphics.lineBetween(handle1.x, handle1.y, handle2.x, handle2.y);
                    graphics2.lineBetween(handle3.x, handle3.y, handle4.x, handle4.y);
                    graphics3.lineBetween(handle5.x, handle5.y, handle6.x, handle6.y);
                    
                // Überprüfung ob Container schon angepinnt wurden    
                if (handle2.x > 500) {
                    if (handle2.y === 300) {
                        zone_4.disableInteractive(); 
                    } else if (handle2.y === 400) {
                        zone_5.disableInteractive();
                    }else if (handle2.y === 500) {
                        zone_6.disableInteractive();
                    }
                } else if (handle4.x > 500) {
                    if (handle4.y === 300) {
                        zone_4.disableInteractive(); 
                    } else if (handle4.y === 400) {
                        zone_5.disableInteractive();
                    }else if (handle4.y === 500) {
                        zone_6.disableInteractive();
                    }                
                } else if (handle6.x > 500) {
                    if (handle6.y === 300) {
                        zone_4.disableInteractive(); 
                    } else if (handle6.y === 400) {
                        zone_5.disableInteractive();
                    }else if (handle6.y === 500) {
                        zone_6.disableInteractive();
                    }                }
        });

        this.input.on('dragend', function (pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Container, dropped) {
            if (!dropped) {
                if(gameObject.input){
                    gameObject.x = gameObject.input.dragStartX;
                    gameObject.y = gameObject.input.dragStartY;
                }
            }
        });

        var notice_count = localStorage.getItem('notice_count_2');
        if (notice_count == 'right') {
            this.scene.launch('notice', {
                text: 'Wenn du die Fragen per Tastatur spielen möchtest musst du die Zahl eines Kärtchens auf der linken Seite drücken und anschließend die Zahl des Kärtchens auf der rechten Seite, mit dem du das erste verbinden willst.',
                caller: 'QuestionLineScene'
            })
        }
        localStorage.setItem('notice_count_2', "false");

        // Button Überprüfen
        length = this.rightAnswer.length
        answerArray = this.rightAnswer
        const clickButton = this.add.text(850, 160, `Überprüfen`, {
            color: 'black',
            fontSize: '25px',
            stroke: 'black',
            strokeThickness: 2,
            backgroundColor: 'lightgreen'
        })
            .setInteractive()

        // Überprüfen der Antworten
        clickButton.on('pointerdown', () => {
            this.sound.remove(this.tension)
           // this.backgroundmusic.play()
            var right = 0;
            var wrong = 0;
            var count = 0;
            var test = 0;
            var arr = [container_1, container_2, container_3, container_4, container_5, container_6]
            var handleArr = [handle2, handle4, handle6]
            for (var i = 0; i < arr.length; i++) {
                count = 0;
                for (var j = 0; j < length; j++) {
                    if (answerArray[j][1] == arr[i].name) {
                        if (handleArr[j].y >= arr[i].y && handleArr[j].y <= arr[i].y + 40) {
                            right = right + 1;
                            break
                        } else {
                            wrong = wrong + 1;
                            break
                        }
                    } else {
                        count = count + 1
                    }

                }
            }
            if (wrong == 0) {
                this.bool = true
                this.scene.launch('warning', {
                    text: this.explanation,
                    header: "Das war richtig. \n Du kannst in den nächsten Raum.",
                    caller: 'questionLineScene',
                    answer: true,
                })

                this.scene.pause()

            } else {
                this.bool = false
                this.scene.launch('warning', {
                    header: "Das war leider falsch. \n Du musst das Level nochmal spielen.",
                    caller: 'questionLineScene',
                    answer: false,
                })
                this.scene.pause()
            }
        })

        const clickButton_2 = this.add.text(850, 210, `Neustart`, {
            color: 'black',
            fontSize: '25px',
            stroke: 'black',
            strokeThickness: 2,
            backgroundColor: 'lightgrey'
        })
            .setInteractive()

        // Neustart der Scene
        clickButton_2.on('pointerdown', () => {
            this.scene.restart();
            counter++;
        })


        // Frageszene schließen und Szene davor weiterlaufen lassen
        this.events.on('resume', () => {
            this.scene.stop()
            this.scene.start('equipment')
            this.scene.start('mapButton')
            this.scene.start('musikButton')
            this.scene.resume(this.caller, {bool: this.bool, caller: 'questionLineScene'})
        })

        // Tastaturkeys zum Beantworten der Frage belegen
        if(this.input?.keyboard){
            KeyOne = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ONE)
            KeyNumOne = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NUMPAD_ONE)
            KeyTwo = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.TWO)
            KeyNumTwo = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NUMPAD_TWO)
            KeyThree = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.THREE)
            KeyNumThree = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NUMPAD_THREE)
            KeyFour = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.FOUR)
            KeyNumFour = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NUMPAD_FOUR)
            KeyFive = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.FIVE)
            KeyNumFive = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NUMPAD_FIVE)
            KeySix = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SIX)
            KeyNumSix = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NUMPAD_SIX)
            KeyEsc = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ESC)
            KeyEnt = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER)
            KeyNew = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.N)
            cursor = this.input.keyboard.createCursorKeys()
        }

        //Tasten belegen für Musiksteuerung
        if(this.input?.keyboard){
            KeyM = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M)
        }
    }

    drawLine() {
        graphics.lineStyle(2, 0xff00110, 1);
        graphics.lineBetween(handle1.x, handle1.y, handle2.x, handle2.y);
        graphics2.lineBetween(handle3.x, handle3.y, handle4.x, handle4.y);
        graphics3.lineBetween(handle5.x, handle5.y, handle6.x, handle6.y);
    }

    resolve() {
        this.sound.remove(this.tension)
        var right = 0;
        var wrong = 0;
        var count = 0;
        var arr = [container_1, container_2, container_3, container_4, container_5, container_6]
        var handleArr = [handle2, handle4, handle6]
        for (var i = 0; i < arr.length; i++) {
            count = 0;
            for (var j = 0; j < length; j++) {
                if (answerArray[j][1] == arr[i].name) {
                    if (handleArr[j].y >= arr[i].y && handleArr[j].y <= arr[i].y + 40) {
                        right = right + 1;
                        break
                    } else {
                        wrong = wrong + 1;
                        break
                    }
                } else {
                    count = count + 1
                }

            }
        }
        console.log("Wrong: ", wrong)
        if (wrong == 0) {
            this.bool = true
            this.scene.launch('warning', {
                text: 'Das war richtig. Du kannst in den nächsten Raum vordringen.',
                caller: 'questionLineScene',
                answer: true,
            })
            this.scene.pause()

        } else {
            this.bool = false
            this.scene.launch('warning', {
                text: '',
                caller: 'questionLineScene',
                answer: false,
            })
            this.scene.pause()
        }
    }


    // Tastatureingaben abfangen, um Frage zu beantworten oder zu schließen
    update() {
        if (Phaser.Input.Keyboard.JustDown(KeyFour) || Phaser.Input.Keyboard.JustDown(KeyNumFour)) {
            if (Phaser.Input.Keyboard.JustDown(KeyOne) || Phaser.Input.Keyboard.JustDown(KeyNumOne)) {
                if (handle2.data.values.move < 1) {
                    this.children.bringToTop(handle2)
                    handle2.x = container_4.x - 200
                    handle2.y = container_4.y
                    handle2.disableInteractive()
                    handle2.data.values.move++
                    this.drawLine();
                }
            } else if (Phaser.Input.Keyboard.JustDown(KeyTwo) || Phaser.Input.Keyboard.JustDown(KeyNumTwo)) {
                if (handle4.data.values.move < 1) {
                    this.children.bringToTop(handle4)
                    handle4.x = container_4.x - 200
                    handle4.y = container_4.y
                    handle4.disableInteractive()
                    handle4.data.values.move++
                    this.drawLine();
                }
            } else if (Phaser.Input.Keyboard.JustDown(KeyThree) || Phaser.Input.Keyboard.JustDown(KeyNumThree)) {
                if (handle6.data.values.move < 1) {
                    this.children.bringToTop(handle6)
                    handle6.x = container_4.x - 200
                    handle6.y = container_4.y
                    handle6.disableInteractive()
                    handle6.data.values.move++
                    this.drawLine();
                }
            }
        } else if (Phaser.Input.Keyboard.JustDown(KeyFive) || Phaser.Input.Keyboard.JustDown(KeyNumFive)) {
            if (Phaser.Input.Keyboard.JustDown(KeyOne) || Phaser.Input.Keyboard.JustDown(KeyNumOne)) {
                if (handle2.data.values.move < 1) {
                    this.children.bringToTop(handle2)
                    handle2.x = container_5.x - 200
                    handle2.y = container_5.y
                    this.drawLine()
                    handle2.disableInteractive()
                    handle2.data.values.move++
                }
            } else if (Phaser.Input.Keyboard.JustDown(KeyTwo) || Phaser.Input.Keyboard.JustDown(KeyNumTwo)) {
                if (handle4.data.values.move < 1) {
                    this.children.bringToTop(handle4)
                    handle4.x = container_5.x - 200
                    handle4.y = container_5.y
                    this.drawLine()
                    handle4.disableInteractive()
                    handle4.data.values.move++
                }
            } else if (Phaser.Input.Keyboard.JustDown(KeyThree) || Phaser.Input.Keyboard.JustDown(KeyNumThree)) {
                if (handle6.data.values.move < 1) {
                    this.children.bringToTop(handle6)
                    handle6.x = container_5.x - 200
                    handle6.y = container_5.y
                    this.drawLine()
                    handle6.disableInteractive()
                    handle6.data.values.move++
                }
            }
        } else if (Phaser.Input.Keyboard.JustDown(KeySix) || Phaser.Input.Keyboard.JustDown(KeyNumSix)) {
            if (Phaser.Input.Keyboard.JustDown(KeyOne) || Phaser.Input.Keyboard.JustDown(KeyNumOne)) {
                if (handle2.data.values.move < 1) {
                    this.children.bringToTop(handle2)
                    handle2.x = container_6.x - 200
                    handle2.y = container_6.y
                    this.drawLine()
                    handle2.disableInteractive()
                    handle2.data.values.move++
                }
            } else if (Phaser.Input.Keyboard.JustDown(KeyTwo) || Phaser.Input.Keyboard.JustDown(KeyNumTwo)) {
                if (handle4.data.values.move < 1) {
                    this.children.bringToTop(handle4)
                    handle4.x = container_6.x - 200
                    handle4.y = container_6.y
                    this.drawLine();
                    handle4.disableInteractive()
                    handle4.data.values.move++
                }
            } else if (Phaser.Input.Keyboard.JustDown(KeyThree) || Phaser.Input.Keyboard.JustDown(KeyNumThree)) {
                if (handle6.data.values.move < 1) {
                    this.children.bringToTop(handle6)
                    handle6.x = container_6.x - 200
                    handle6.y = container_6.y
                    this.drawLine();
                    handle6.disableInteractive()
                    handle6.data.values.move++
                }
            }
        }

        if (Phaser.Input.Keyboard.JustDown(KeyEsc)) {
            // Escape gedrückt: Frage schließen ohne zu beantworten
            this.scene.stop()
            this.scene.start('equipment')
            this.scene.start('mapButton')
            this.scene.start('musikButton')
            this.scene.resume(this.caller, {caller: 'questionLineScene'})
        }
        if (Phaser.Input.Keyboard.JustDown(KeyEnt)) {
            // Enter gedrückt: Frage überprüfen
            this.resolve();
        }
        if (Phaser.Input.Keyboard.JustDown(KeyNew)) {
            // N gedrückt: Fragesezene neu starten
            this.scene.restart();
        }
    }
}


