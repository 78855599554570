import Phaser from "phaser";
import { sceneEvents } from "../../events/EventsCenter";

export default class StopwatchScene extends Phaser.Scene {
  initialTime: number = 0;
  private countdownButton!: Phaser.GameObjects.GameObject;
  private text!: Phaser.GameObjects.Text;
  private timedEvent!: Phaser.Time.TimerEvent;

  constructor() {
    super('stopwatchscene');
  }

  create() {
    // Erzeugt Textfeld für Countdown
    this.countdownButton = this.add.rectangle(525, 25, 115, 50, 0x888888).setScrollFactor(0);
    this.text = this.add.text(493, 6, this.formatTime(this.initialTime), {
      fontSize: '20px',
      color: '#0a0a0a',
    });
    // Ruft Methode zur grafischen Gestaltung auf
    this.setzeTextFormat(this.text, '40px', 'Calibri');
    // Jede 1000 ms wird onEvent aufgerufen
    this.timedEvent = this.time.addEvent({ delay: 1000, callback: this.onEvent, callbackScope: this, loop: true });

    // Hält die Zeit an
    this.events.on('stopCountdown', () => {
      this.timedEvent.paused = true;
      this.saveStoppedTime();
    });

    // Hört auf das Reset-Ereignis
    sceneEvents.on('resetStopwatch', this.resetStopwatch, this);
  }

  saveStoppedTime() {
    // Speichert die gestoppte Zeit im Local Storage
    localStorage.setItem('stoppedTime', this.formatTime(this.initialTime));
    localStorage.setItem('seconds', this.initialTime.toString());
    const minutes = Math.floor(this.initialTime / 60);
    localStorage.setItem('minutes', minutes.toString());
  }

resetStopwatch() {
  this.initialTime = 0;
  this.text.setText(this.formatTime(this.initialTime));
}

  // Grafische Bearbeitung
  setzeTextFormat(text: Phaser.GameObjects.Text, schriftgroesse: string, schriftart: string) {
    text.setStyle({
      fontSize: schriftgroesse,
      fontFamily: schriftart
    });
  }

  formatTime(seconds: number) {
    // Minutes
    const minutes = Math.floor(seconds / 60);
    // Seconds
    const partInSeconds = seconds % 60;
    // Adds leading zeros to seconds
    const formattedSeconds = partInSeconds.toString().padStart(2, '0');
    // Returns formatted time
    return `${minutes}:${formattedSeconds}`;
  }

  // Stoppuhr
  onEvent() {
    this.initialTime += 1; // Eine Sekunde
    this.text.setText(this.formatTime(this.initialTime));
  }
}
