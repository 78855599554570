import Phaser, { BlendModes } from 'phaser'
import InputText from 'phaser3-rex-plugins/plugins/inputtext.js';

export default class PlayerName extends Phaser.Scene {
    private returnKey!: Phaser.Input.Keyboard.Key;
    private EscButton!: Phaser.GameObjects.GameObject;
    private Textfeld!: Phaser.GameObjects.Rectangle;

    constructor() {
        super('playername');
    }

    create() {        
        // Textfeld erzeugen
        this.Textfeld = this.add.rectangle(515, 300, 300, 70, 0xffffff).setScrollFactor(0)
        this.Textfeld.setInteractive()

        var inputText = new InputText(this, 515, 300, 300, 70, {
            x: 0,
            y: 0,
            width: undefined,
            height: undefined,
        
            type: 'text',    // 'text'|'password'|'textarea'|'number'|'color'|...
        
            // Element properties
            id: undefined,
            text: undefined,
            maxLength: 15,
            minLength: 1,    
            placeholder: 'Dein Name',
            tooltip: undefined,
            readOnly: false,
            spellCheck: false,
            autoComplete: 'off',
        
            // Style properties
            align: 'center',
            paddingLeft: undefined,
            paddingRight: undefined,
            paddingTop: undefined,
            paddingBottom: undefined,
            fontFamily: undefined,
            fontSize: '25px',
            color: '#0a0a0a',
            border: 0,
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            outline: 'none',
        
            selectAll: false
        });

        this.add.existing(inputText);
        

        // Text hinzufügen
        this.add.text(330, 200, 'Wie ist dein Name?', {
            fontSize: '35px'
        })

        this.returnKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ENTER);
        
        // Esc Button erzeugen
        this.EscButton = this.add.rectangle(525, 500, 180, 70, 0xffffff).setScrollFactor(0)
        this.EscButton.setInteractive()
        this.add.text(447, 490, 'Spiel starten', {
            fontSize: '20px',
            color: '#0a0a0a',
        })

        //Nameneingabe schließen
        this.returnKey.on('down', event => {
        //Leerer Name
        if (inputText.text == '') {
            inputText.text = 'Anonym'
        }

            localStorage.setItem('player', inputText.text)
            this.scene.stop()
            this.scene.start('tutorialscene')
        });

        this.EscButton.on('pointerdown', () => {
        //Leerer Name
        if (inputText.text == '') {
            inputText.text = 'Anonym'
        }

            localStorage.setItem('player', inputText.text)
            this.scene.stop()
            this.scene.start('tutorialscene')
        });

    }

}