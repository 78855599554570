import Phaser from 'phaser'

const createThiefAnimations = (anims: Phaser.Animations.AnimationManager) => {
    // Animationen des Thiefs generieren
    anims.create({
        key: 'thief-idle-down',
        frames: [{ key: 'thief', frame: 'run-down-2.png' }]
    })

    anims.create({
        key: 'thief-idle-up',
        frames: [{ key: 'thief', frame: 'run-up-2.png' }]
    })

    anims.create({
        key: 'thief-idle-side',
        frames: [{ key: 'thief', frame: 'run-side-2.png' }]
    })

    anims.create({
        key: 'thief-run-down',
        frames: anims.generateFrameNames('thief', { start: 1, end: 3, prefix: 'run-down-', suffix: '.png' }),
        repeat: -1,
        frameRate: 15
    })

    anims.create({
        key: 'thief-run-up',
        frames: anims.generateFrameNames('thief', { start: 1, end: 3, prefix: 'run-up-', suffix: '.png' }),
        repeat: -1,
        frameRate: 15
    })

    anims.create({
        key: 'thief-run-side',
        frames: anims.generateFrameNames('thief', { start: 1, end: 3, prefix: 'run-side-', suffix: '.png' }),
        repeat: -1,
        frameRate: 15
    })

}



export {
    createThiefAnimations
}
