import { Hints, hints } from "../../assets/classes/Hints";

interface Question {
  id: number;
  category: string;
  text: string;
  options: {
    correct: boolean;
    text: string;
  }[];
  explanation: string;
  rightAnswer: number[];
  done: boolean;
}

class QuestionLoaderDrag {
  private questionText!: string;
  private options!: string[];
  private explanation!: string;
  private randomQuestion: any;
  private rightAnswer: any;
  private questionId!: number;

  constructor() {
    this.questionText = '';
    this.options = [];
    this.randomQuestion = null;
    this.rightAnswer = 0;
    this.explanation = '';
    this.questionId = 0;
  }

  loadQuestion(level) {
    return fetch('./fragen/questionpoolDrag.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Fehler beim Laden der JSON-Datei');
        }
        return response.json();
      })
      .then((jsonData: { questions: Question[] }) => {
        console.log('Geladene JSON-Datei:', jsonData);

        const questions = jsonData.questions;
        const level1Questions = questions.filter(question =>
          question.category === 'Klasse 7'
        );

        const randomIndex = Phaser.Math.Between(0, level1Questions.length - 1);
        const randomQuestion = level1Questions[randomIndex];

        this.questionText = randomQuestion.text;
        this.options = randomQuestion.options.map(option => option.text);
        this.rightAnswer = randomQuestion.rightAnswer;
        this.explanation = randomQuestion.explanation;
        this.questionId = randomQuestion.id;

        console.log('Level-1-Frage:', this.questionText);
        console.log('Antworten:', this.options);
        console.log('Erklärung:', this.explanation);
        console.log('rightAnswerIndex:', this.rightAnswer);
        console.log('questionId:', this.questionId);

        const hint = new Hints(level, this.questionId);
        const hints = hint.getHints();

        return {
          questionText: this.questionText,
          options: this.options,
          rightAnswer: this.rightAnswer,
          explanation: this.explanation,
          questionId: this.questionId,
          hints: hints,
        };
      });
  }
}

export default QuestionLoaderDrag;
