import Phaser from 'phaser'
import {sceneEvents} from "../../events/EventsCenter";


export default class MapButton extends Phaser.Scene {
    
    constructor() {
        super('mapButton');
    }

    create() {
        var buttonKarte = this.add.circle(35, 100, 25, 0x888888).setScrollFactor(0).setInteractive()
        this.add.image(35,100,'mapImage').setScale(0.35).setScrollFactor(0,0);
        this.input.on('gameobjectdown', this.mapClick, this)
    }

    mapClick(pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.GameObject) {
        // Hinweis ausgeben bei Klick auf Karten Button
        sceneEvents.emit('getKarten')
    }

    update() {
    }


}
