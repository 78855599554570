import { Hints, hints } from "../../assets/classes/Hints"

class QuestionLoader {
    private questionText!: string;
    private options!: string[];
    private explanation!: string;
    private randomQuestion: any;
    private rightAnswer: any;
    private questionId!: number;
    private lastQuestionId: number | null;  // Variable für die letzte Frage

    constructor() {
        this.questionText = '';
        this.options = [];
        this.randomQuestion = null;
        this.rightAnswer = 0;
        this.explanation = '';
        this.questionId = 0;
        this.lastQuestionId = null;  // Initialisieren der letzten Frage-ID
    }

    loadQuestion(level) {
        return fetch('./fragen/questionpool.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Fehler beim Laden der JSON-Datei');
                }
                return response.json();
            })
            .then(jsonData => {
                console.log('Geladene JSON-Datei:', jsonData);

                const questions = jsonData.questions;
                const level1Questions = questions.filter(question =>
                    question.category === 'Klasse 7'
                );

                if (level1Questions.length <= 1) {
                    // Wenn nur eine oder keine Frage vorhanden ist, gibt es keine Wiederholung
                    this.randomQuestion = level1Questions[0];
                } else {
                    let randomIndex;
                    let attempts = 0;
                    const maxAttempts = 10;  // Sicherheitsnetz, um Endlosschleifen zu vermeiden

                    // Neue Frage wählen, die nicht die gleiche wie die letzte ist
                    do {
                        randomIndex = Phaser.Math.Between(0, level1Questions.length - 1);
                        this.randomQuestion = level1Questions[randomIndex];
                        attempts++;
                    } while (this.randomQuestion.id === this.lastQuestionId && attempts < maxAttempts);

                    // Falls wir das Sicherheitsnetz erreichen, wählen wir eine beliebige Frage
                    if (this.randomQuestion.id === this.lastQuestionId) {
                        this.randomQuestion = level1Questions[(randomIndex + 1) % level1Questions.length];
                    }
                }

                this.questionText = this.randomQuestion.text;
                this.options = this.randomQuestion.options.map(option => option.text);
                this.rightAnswer = this.randomQuestion.options.findIndex(option => option.correct === true) + 1;
                this.explanation = this.randomQuestion.explanation;
                this.questionId = this.randomQuestion.id;

                // Speichern der aktuellen Frage-ID als letzte Frage-ID
                this.lastQuestionId = this.questionId;

                console.log('Level-1-Frage:', this.questionText);
                console.log('Antworten:', this.options);
                console.log('Erklärung:', this.explanation);
                console.log('rightAnswerIndex:', this.rightAnswer);
                console.log('questionId:', this.questionId);

                const hint = new Hints(level, this.questionId);
                const hints = hint.getHints();

                return {
                    questionText: this.questionText,
                    options: this.options,
                    rightAnswer: this.rightAnswer,
                    explanation: this.explanation,
                    hints: hints
                };
            })
            .catch(error => {
                console.error('Fehler beim Laden der Frage:', error);
            });
    }
}

export default QuestionLoader;