export class Hints {
    private matchingHints: Hint[] = [];

    constructor(level: number, question: number) {
        // Passenden Hinweis zur Frage/zum Level aussgeben, wenn Spieler das Walkie Talkie aufruft
        var hint
        for (hint in hints) {
            if (hints[hint].level === level) {
                this.matchingHints.push(hints[hint])
            }
        }
        for (hint in hints) {
            if (hints[hint].question === question) {
                this.matchingHints.push(hints[hint])
            }
        }
    }

    public getHints() {
        // alle Hinweise zurückgeben
        return this.matchingHints
    }
}

export const hints: Hint[] = [
    // Belegung der Hinweise, Zuweisung über Level oder Frage
    {
        level: 1,
        question: 0,
        id: 1,
        content: 'Willkommen! Lass uns gemeinsam die Welt retten!'
    },
    {
        level: 2,
        question: 0,
        id: 2,
        content: 'Vergiss unsere Mission nicht. Probiere in das Rechenzentrum vorzudringen.'
    },
    {
        level: 3,
        question: 0,
        id: 3,
        content: 'Vergiss unsere Mission nicht. Probiere in das Rechenzentrum vorzudringen.'
    },
    {
        level: 4,
        question: 0,
        id: 4,
        content: 'Pass auf die Wachmänner auf! Wenn sie dich erwischen, musst du das Level von vorne beginnen!'
    },
    {
        level: 4,
        question: 0,
        id: 5,
        content: 'Manchmal gibt es Hinweise auf Schreibtischen oder Schildern, die an der Wand hängen. Vielleicht kannst du dabei was entdecken...'
    },
    {
        level: 5,
        question: 0,
        id: 6,
        content: 'Glückwunsch du hast die zweite Sicherheitsstufe erreicht! Versuche weiter vorzudringen.'
    },
    {
        level: 6,
        question: 0,
        id: 7,
        content: 'Pass auf den Wachmann auf! Wenn er dich erwischt, musst du das Level von vorne beginnen!'
    },
    {
        level: 7,
        question: 0,
        id: 8,
        content: 'Manchmal gibt es Hinweise bei oder hinter Bildern.'
    },
    {
        level: 7,
        question: 0,
        id: 9,
        content: 'Glückwunsch, du bist in die Büroräume vorgedrungen. Hier warten neue Herausforderungen auf dich!'
    },
    {
        level: 8,
        question: 0,
        id: 10,
        content: 'Jetzt musst du nur noch bis zum Ende des Raumes laufen, ohne erwischt zu werden!'
    },
    {
        level: 0,
        question: 1,
        id: 11,
        content: 'Wenn du einmal etwas im Internet gepostet hast, ist es nur sehr schwer wieder zu löschen. Pass also auf, was du im Internet teilst.'
    },
    {
        level: 0,
        question: 2,
        id: 12,
        content: 'Jeder hat ein Recht auf Privatsphäre.'
    },
    {
        level: 0,
        question: 3,
        id: 13,
        content: 'Daten sind das Gold des digitalen Zeitalters. Die Daten von allen Usern gemeinsam sind ca 200 Milliarden Euro wert.'
    },
    {
        level: 0,
        question: 4,
        id: 14,
        content: 'Wenn man überall das gleiche Passwort verwendet und gehackt wird, haben die Angreifer Zugriff auf alle Accounts.'
    },
    {
        level: 0,
        question: 5,
        id: 15,
        content: 'Cookies sind Daten.'
    },
    {
        level: 0,
        question: 6,
        id: 16,
        content: 'Cookies sind Daten, die eine Webseite auf dem eigenen Computer zwischenspeichert, wenn man diese besucht.'
    },
    {
        level: 0,
        question: 7,
        id: 17,
        content: 'Besuchte Websiten können Informationen über einen speichern.'
    },
    {
        level: 0,
        question: 8,
        id: 18,
        content: 'Youtuber können zum Beispiel über Promo-Links für Sponsoren werben.'
    },
    {
        level: 0,
        question: 9,
        id: 19,
        content: 'Informationen sollten regelmäßig gelöscht oder deren Speicherung vermieden werden.'
    },
    {
        level: 0,
        question: 10,
        id: 20,
        content: 'Daten sollten wenn notwendig an vertrauenswürdige Websiten übergebe werden.'
    },
    {
        level: 0,
        question: 11,
        id: 21,
        content: 'Die DSGVO (Datenschutzgrundverordnung) ist eine Reihe von Gesetzen der EU.'
    },
    {
        level: 0,
        question: 12,
        id: 22,
        content: 'Daten, die bei der Nutzung des Dienstes erhoben werden, sind Verbindungsdaten. Dazu gehört zum Beipsiel die Rufnummer.'
    }

]
