import Phaser from 'phaser'
import {sceneEvents} from "../../events/EventsCenter";

var KeyH;

export default class Equipment extends Phaser.Scene {


    constructor() {
        super('equipment');
    }

    create() {
        // Walkie-Talkie Icon generieren
        var circle = this.add.ellipse(950, 75, 100, 100, 0xffffff, 75)
            .setStrokeStyle(2, 0xffffff)
            .setInteractive()
        var walkietalkie = this.add.image(950, 70, 'walkietalkie')
            .setScale(0.75)
        this.input.on('gameobjectdown', this.handleClick, this)

        if(this.input?.keyboard) {
            KeyH = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.H)
        }
    }

    handleClick(pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.GameObject) {
        // Hinweis ausgeben bei Klick auf Walkie-Talkie
        sceneEvents.emit('getHint')
    }

    update() {
        if (Phaser.Input.Keyboard.JustDown(KeyH)) {

            sceneEvents.emit('getHint')

        }
    }


}
