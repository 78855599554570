export class Questions {
    private availableQuestions: Question[] = []

    constructor(difficulty: number) {//
        // Richtige Frage (noch nicht beantwortet und passender Schwierigkeitsgrad)
        questions.forEach(question => {
                if (!question.done && difficulty === question.difficulty) {
                    this.availableQuestions.push(question)
                }
            }
        )
    }

    randomizeQuestion(): Question {
        // Fragen randomisieren
        const num = Math.floor(Math.random() * this.availableQuestions.length)
        return this.availableQuestions[num]

    }
}

export const questions: Question[] = [
    {
        // Belegung der Fragen, Zuweisung über Schwierigkeitsgrad
        id: 1,
        difficulty: 1,
        question: 'Darf ich Fotos und Videos von mir oder Freunden ins Internetstellen oder über das Smartphone verschicken?',
        answers: {
            answer1: '1: Nur wenn meine Freunde auf den Fotos gut aussehen',
            answer2: '2: Nein. Ich muss meine Freunde um Erlaubnis fragen und mir das gut überlegen',
            answer3: '3: Ja. Erst einmal ins Internet stellen. Löschen kann ich sie immer noch',
        },
        erklareung: 'Nein, du darfst nicht einfach Fotos und Videos von dir oder deinen Freunden ins Internet stellen oder über das Smartphone verschicken. Bevor du das tust, musst du immer deine Freunde um Erlaubnis fragen und dir gut überlegen, ob du wirklich möchtest, dass diese Bilder und Videos für andere sichtbar sind. ',
        rightAnswer: 2,
        done: false,
        explanation: ""
    },
    {
        id: 2,
        difficulty: 1,
        question: 'Darfst du im Internet jede Seite anklicken und Internetfotos von deinem Lieblingsstar auf deinem Computer speichern?',
        answers: {
            answer1: '1: Nein. Ich darf nicht alle Seiten anklicken & Texte, Bilder, Musik speichern',
            answer2: '2: Ja. Na klar! Sonst wäre es ja nicht im Internet zu finden',
            answer3: '3: Nur wenn die Bilder nichts kosten',
        },
        erklareung: 'Das Internet ist wie eine riesige Bibliothek mit vielen verschiedenen Büchern, aber nicht alle Bücher sind für Kinder geeignet. Ähnlich ist es im Internet. Es gibt viele verschiedene Websites, aber nicht alle sind sicher oder passend für Kinder',
        rightAnswer: 1,
        done: false,
        explanation: ""
    },
    {
        id: 3,
        difficulty: 1,
        question: 'Warum sind viele Apps kostenlos?',
        answers: {
            answer1: '1: Die App-Betreiber erhalten Geld von der GEZ und vom Staat',
            answer2: '2: Die Kosten sind schon in den Handy- und Internettarifen enthalten',
            answer3: '3: Mit Werbeanzeigen und Daten der Nutzerinnen und Nutzer wird Geld verdient',
        },
        erklareung: 'Viele Apps sind kostenlos, weil die Entwickler Geld verdienen, indem sie Werbeanzeigen in der App anzeigen oder die Daten der Nutzerinnen und Nutzer sammeln und verkaufen. So können sie die Kosten für die Entwicklung und den Betrieb der App decken.',
        rightAnswer: 3,
        done: false,
        explanation: ""
    },
    {
        id: 4,
        difficulty: 1,
        question: 'Auf vielen Webseiten muss man sich einen Zugang mit Passwort anlegen – wie gehst Du vor?',
        answers: {
            answer1: '1: Ich verwende eine Zahlenkombination, die ich mir gut merken kann',
            answer2: '2: Ich verwende ein Passwort aus Zahlen- und Buchstabenkombi und schreibe es auf einen Zettel',
            answer3: '3: Ich überlege mir einen Merkspruch und erstelle aus den Anfangsbuchstaben das Passwort',
        },
        erklareung: 'Um mir ein Passwort für eine Webseite anzulegen, überlege ich mir einen Merkspruch, zum Beispiel "Ich liebe Bananen und Äpfel". Dann nehme ich die Anfangsbuchstaben der Wörter, also "ilbaa", und verwende sie als mein Passwort. So kann ich es leichter merken und es ist sicherer.',
        rightAnswer: 3,
        done: false,
        explanation: ""
    },
    {
        id: 5,
        difficulty: 1,
        question: 'Wofür wird ein Cookie verwendet?',
        answers: {
            answer1: '1: Zum Speichern bestimmter Inhalte durch einen Internetanbieter',
            answer2: '2: Zum Versenden geheimer Nachrichten',
            answer3: '3: Als Währung zum Bezahlen bei Webshops',
        },
        erklareung: 'Ein Cookie wird verwendet, um bestimmte Informationen zu speichern, damit eine Internetseite oder ein Internetanbieter sie später wiedererkennen kann. Zum Beispiel kann ein Cookie sich merken, welche Sprache du auf einer Webseite ausgewählt hast, damit sie dir jedes Mal in dieser Sprache angezeigt wird, wenn du die Seite besuchst.',
        rightAnswer: 1,
        done: false,
        explanation: ""
    },
    {
        id: 6,
        difficulty: 1,
        question: 'Wie kann man Cookies löschen lassen?',
        answers: {
            answer1: '1: Es ist ein Antrag an den Webseitenbetreiber zu stellen',
            answer2: '2: Man nutzt dafür eine spezielle Funktion des Browsers',
            answer3: '3: Durch das Ausschalten von PC, Tablet oder Smartphone',
        },
        erklareung: 'Um Cookies zu löschen, kann man die spezielle Funktion des Browsers verwenden. Das bedeutet, dass man in den Einstellungen des Browsers nach den Cookie-Einstellungen sucht und dort die Option auswählt, um die Cookies zu löschen. Dadurch werden die gespeicherten Informationen, die Websites auf dem Gerät hinterlassen haben, entfernt.',
        rightAnswer: 2,
        done: false,
        explanation: ""
    },
    {
        id: 7,
        difficulty: 1,
        question: 'Was bedeutet es, Spuren im Internet zu hinterlassen?',
        answers: {
            answer1: '1: Ob ich alleine vor dem PC sitze oder nicht',
            answer2: '2: Ob ich ein Android- oder Apple-Smartphone nutze',
            answer3: '3: Welche Seiten ich gestern angesehen habe',
        },
        erklareung: 'Spuren im Internet hinterlassen bedeutet, dass unsere Aktivitäten und Informationen, die wir online machen, gespeichert werden. Zum Beispiel welche Websites wir besuchen, welche Apps wir nutzen oder welche Informationen wir teilen. Diese Spuren können von Unternehmen oder anderen Personen gesehen und analysiert werden, um mehr über uns herauszufinden.',
        rightAnswer: 2,
        done: false,
        explanation: ""
    }
]
