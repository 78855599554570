import Phaser from 'phaser'

var KeyOne
var KeyNumOne
var KeyTwo
var KeyNumTwo
var KeyThree
var KeyNumThree
var KeyEsc
var KeyM

export default class QuestionScene extends Phaser.Scene {
    // Variablen belegen
    private question!: string
    private answer1!: string
    private answer2!: string
    private answer3!: string
    private rightAnswer!: number
    private caller!: string
    private explanation!: string
    private bool!: boolean
    private tension!: Phaser.Sound.BaseSound
    private backgroundmusic!: Phaser.Sound.BaseSound

    constructor() {
        super('questionScene')
    }

    init(data) {
        // Parameter in der Scene initialisieren
        this.question = data.question
        this.answer1 = data.answer1
        this.answer2 = data.answer2
        this.answer3 = data.answer3
        this.rightAnswer = data.rightAnswer
        this.explanation = data.explanation
        this.caller = data.caller
    }

    create() {
        // Szene generieren
        this.scene.stop('hints')
        this.scene.stop('equipment')

        //Musik anschalten, wenn in vorherigem Level Musik an war
        var flag = localStorage.getItem('flag');

        if (flag == "true") {
            this.backgroundmusic = this.sound.get('backgroundmusic')
            this.sound.stopAll()
            this.tension = this.sound.add('question', {volume: 0.1, loop: true})
            this.tension.play()
        }


        // Hintergrundbild
        this.add.image(512, 250, 'background')

        // Rechteck für Die Frage
        const questionRect = this.add.graphics()
        questionRect.fillStyle(0xFFFAFA)
        questionRect.fillRoundedRect(50, 50, 924, 150, 25)

        // Rechtecke für die Antworten
        const answer1RectR = this.add.rectangle(512, 270, 824, 70, 0xFFFAFA).setInteractive().setName('1')
        const answer2RectR = this.add.rectangle(512, answer1RectR.y + answer1RectR.height + 10, 824, 70, 0xFFFAFA).setInteractive().setName('2')
        const answer3RectR = this.add.rectangle(512, answer2RectR.y + answer2RectR.height + 10, 824, 70, 0xFFFAFA).setInteractive().setName('3')

        // Texte der Antworten und Fragen
        this.add.text(110, 100, this.question, {
            fontSize: '18px',
            color: '0xffff00',
            align: 'center',
            wordWrap: {
                width: 824
            }
        })

        // Antwort 1
        this.add.text(160, answer1RectR.y - 15, this.answer1, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 724
            }
        })
        // Antwort 2
        this.add.text(160, answer2RectR.y - 15, this.answer2, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 724
            }
        })
        // Antwort 3
        this.add.text(160, answer3RectR.y - 15, this.answer3, {
            fontSize: '14px',
            color: '0xffff00',
            align: 'left',
            wordWrap: {
                width: 724
            }
        })

        // Events für Hover-in Hover-out und Klicken
        this.input.on('gameobjectover', this.gameObjectHover, this)
        this.input.on('gameobjectout', this.gameObjectOut, this)
        this.input.on('gameobjectdown', this.gameObjectDown, this)



        // Frageszene schließen und Szene davor weiterlaufen lassen
        this.events.on('resume', () => {
            this.scene.stop()
            this.scene.resume(this.caller, {bool: this.bool, caller: 'questionScene', pausebool: false})
        })

        // Tastaturkeys zum Beantworten der Frage belegen
        if(this.input?.keyboard) {
            KeyOne = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ONE)
            KeyNumOne = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NUMPAD_ONE)
            KeyTwo = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.TWO)
            KeyNumTwo = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NUMPAD_TWO)
            KeyThree = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.THREE)
            KeyNumThree = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.NUMPAD_THREE)
            KeyEsc = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.ESC)
        }

        //Tasten belegen für Musiksteuerung
        if(this.input?.keyboard){
            KeyM = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M)
        }
    }

    // Event das beim Reinhovern aufgerufen wird
    gameObjectHover(pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Rectangle) {
        gameObject.setFillStyle(0xC0C0C0)
    }

    // Event das beim Raushovern aufgerufen wird
    gameObjectOut(pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Rectangle) {
        gameObject.setFillStyle(0xFFFAFA)
    }

    // Event das beim Klicken aufgerufen wird
    gameObjectDown(pointer: Phaser.Input.Pointer, gameObject: Phaser.GameObjects.Rectangle) {
        //solveQuestion aufrufen
        this.solveQuestion(gameObject.name)
    }

    // Frage beantworten, entsprechende Sounds abspielen
    solveQuestion(chosenAnswer: String) {


        var rightAnswerSound = this.sound.add('rightAnswer', {volume: 0.1})
        var wrongAnswerSound = this.sound.add('wrongAnswer', {volume: 0.1})

        this.sound.remove(this.tension)

        console.log(this.rightAnswer.toString() == chosenAnswer)
        if (this.rightAnswer.toString() == chosenAnswer) {
            this.bool = true
            var flag = localStorage.getItem('flag')
            if (flag == "true") {
                rightAnswerSound.play()

            }

            // Warningszene mit Parametern für richtige Antwort aufrufen
            this.scene.launch('warning', {
                text: this.explanation,
                header: 'Das war richtig. \n Du kannst in den nächsten Raum.',
                caller: 'questionScene'
            })
            this.scene.pause()
        } else {
            this.bool = false
            var flag = localStorage.getItem('flag')
            if (flag == "true") {
                wrongAnswerSound.play()
            }


            // Warningszene mit Parametern für falsche Antwort aufrufen
            this.scene.launch('warning', {
                text: '',
                header: 'Das war leider falsch. \n Du musst das Level nochmal spielen.',
                caller: 'questionScene'
            })
            this.scene.pause()
        }


    }

    // Tastatureingaben abfangen, um Frage zu beantworten oder zu schließen
    update() {
        if (Phaser.Input.Keyboard.JustDown(KeyOne) || Phaser.Input.Keyboard.JustDown(KeyNumOne)) {
            // 1 oder Numpad 1 gedrückt
            this.solveQuestion('1')
        } else if (Phaser.Input.Keyboard.JustDown(KeyTwo) || Phaser.Input.Keyboard.JustDown(KeyNumTwo)) {
            // 2 oder Numpad 2 gedrückt
            this.solveQuestion('2')
        } else if (Phaser.Input.Keyboard.JustDown(KeyThree) || Phaser.Input.Keyboard.JustDown(KeyNumThree)) {
            // 3 oder Numpad 3 gedrückt
            this.solveQuestion('3')
        } else if (Phaser.Input.Keyboard.JustDown(KeyEsc)) {
            // Escape gedrückt: Frage schließen ohne zu beantworten
            this.scene.stop()
            this.scene.start('equipment')
            this.scene.start('mapButton')
            this.scene.start('musikButton')
            this.scene.resume(this.caller, {caller: 'questionScene'})
        }

    }
}
