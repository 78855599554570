export class Karten {
    private matchingKarten: Karte[] = [];

    constructor(level: number) {
        // Passenden Karte zum Level aussgeben, wenn Spieler die Karte aufruft
        var karte
        for (karte in karten) {
            if (karten[karte].level === level) {
                this.matchingKarten.push(karten[karte])
            }
        }
    }

    public getKarten() {
        // alle Karten zurückgeben
        return this.matchingKarten
    }
}

export const karten: Karte[] = [
    // Belegung der Karten
    {
        level: 1,
        id: 1,
        content: 'Karte1',
    },
    {
        level: 2,
        id: 2,
        content: 'Karte2',
    },
    {
        level: 3,
        id: 3,
        content: 'Karte3',
    },
    {
        level: 4,
        id: 4,
        content: 'Karte4',
    },
    {
        level: 5,
        id: 5,
        content: 'Karte5',
    },
    {
        level: 6,
        id: 6,
        content: 'Karte6',
    },
    {
        level: 7,
        id: 7,
        content: 'Karte7',
    },
    {
        level: 8,
        id: 8,
        content: 'Karte8',
    },
    {
        level: 9,
        id: 9,
        content: 'Karte9',
    }

]
