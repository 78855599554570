import Phaser from 'phaser'

export default class FinalEnd extends Phaser.Scene {
    private EscButton!: Phaser.GameObjects.Rectangle;
    private returnKey!: Phaser.Input.Keyboard.Key;

    private score!: number;

    constructor() {
        super('finalend');
    }

    create() {        
        //Ergebnisse (Punkte erhalten)
        console.log(localStorage.getItem('stoppedtime'))
        console.log(localStorage.getItem('seconds'))
        console.log(localStorage.getItem('minutes'))
        
        // Text hinzufügen
        this.add.text(300, 200, 'Misson Accomplished!', {
            fontSize: '35px'
        })

        this.add.text(300, 300, 'Du hast den Auftrag erfüllt!', {
            fontSize: '25px'
        })


        this.add.text(300, 400, 'Music by Ross Bugden \n https://www.youtube.com/c/RossBugden', {
            fontSize: '14px'
        })

        this.time.delayedCall(5000, () => {
            this.scene.stop('finalend')
            this.scene.stop('stopwatchscene')
            this.scene.start('highscore')
        })

    }


}


