import Phaser from 'phaser'

enum Direction {
    UP,
    DOWN,
    LEFT,
    RIGHT
}

const randomDirection = (exclude: Direction) => {
    // Officer läuft zufällig durch den Raum
    let newDirection = Phaser.Math.Between(0, 3)
    while (newDirection === exclude) {
        newDirection = Phaser.Math.Between(0, 3)
    }

    return newDirection
}

export default class Officer extends Phaser.Physics.Arcade.Sprite {
    private direction = Direction.RIGHT
    private moveEvent: Phaser.Time.TimerEvent

    constructor(scene: Phaser.Scene, x: number, y: number, texture: string, frame?: string | number) {
        super(scene, x, y, texture, frame)

        this.anims.play('officer-idle-down')

        scene.physics.world.on(Phaser.Physics.Arcade.Events.TILE_COLLIDE, this.handleTileCollision, this)

        // Officer läuft zufällig durch den Raum
        this.moveEvent = scene.time.addEvent({
            delay: 4000,
            callback: () => {
                this.direction = randomDirection(this.direction)
            },
            loop: true
        })
    }

    destroy(fromScene?: boolean) {
        // Destroyen
        this.moveEvent.destroy()

        super.destroy(fromScene)
    }

    private handleTileCollision(go: Phaser.GameObjects.GameObject, tile: Phaser.Tilemaps.Tile) {
        // Richtung ändern, wenn Objekt im Weg
        if (go !== this) {
            return
        }

        this.direction = randomDirection(this.direction)
    }

    preUpdate(t: number, dt: number) {
        super.preUpdate(t, dt)

        const speed = 50

        // Switch für Richtungsänderungen
        switch (this.direction) {
            case Direction.UP:
                this.setVelocity(0, -speed)
                this.anims.play('officer-run-up', true)
                break

            case Direction.DOWN:
                this.setVelocity(0, speed)
                this.anims.play('officer-run-down', true)
                break

            case Direction.LEFT:
                this.setVelocity(-speed, 0)
                this.anims.play('officer-run-side', true)
                this.scaleX = 1
                this.body.offset.x = 0
                break

            case Direction.RIGHT:
                this.setVelocity(speed, 0)
                this.anims.play('officer-run-side', true)
                this.scaleX = -1
                this.body.offset.x = 32
                break
        }
    }
}
